import React, { useState } from 'react';
import './CustomForm.css';

const CustomForm = ({ onFormSubmit }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [source, setSource] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formId = '1FAIpQLSeny8rZvHQc_c7czH5heFZ2kS9v7JoAxg6XDfM8ycAjNiUNhQ';
    const formAction = `https://docs.google.com/forms/d/e/${formId}/formResponse`;
    const formData = new FormData();
    formData.append('entry.2005620554', name);
    formData.append('entry.1826080097', email);
    formData.append('entry.1166974658', phone); // Replace with actual field ID for "Phone Number"
    formData.append('entry.839337160', source.join(', ')); // Replace with actual field ID for "Where did you hear about us?"

    try {
      await fetch(formAction, {
        method: 'POST',
        body: formData,
        mode: 'no-cors'
      });
      onFormSubmit();
      setName('');
      setEmail('');
      setPhone('');
      setSource([]);
    } catch (error) {
      alert('Error submitting form');
    }
  };

  const handleSourceChange = (e) => {
    const value = e.target.value;
    setSource((prevSource) =>
      prevSource.includes(value)
        ? prevSource.filter((item) => item !== value)
        : [...prevSource, value]
    );
  };

  return (
    
    <form className="custom-form" onSubmit={handleSubmit}>
        <div className='' style={{marginBottom: '15px'}}>
        <h2  style={{fontWeight: '900', fontSize: '1.5rem', textAlign: 'center'}}> Join the Waitlist Today and Be the First to Know!</h2>
        </div>
      <div className="form-group">
        <label htmlFor="name">Name:</label>
        <input
          type="text"
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="phone">Phone Number:</label>
        <input
          type="tel"
          id="phone"
          pattern="[0-9+]*"
          inputmode="numeric"
          value={phone}
          title="Please use only numbers or '+' symbol"
          onChange={(e) => setPhone(e.target.value)}
          required
        />
        
      </div>
      <div className="form-group">
        <label htmlFor="source">Where did you hear about us?</label>
        <div className="dropdown-checkbox">
          <label>
            <input
              type="checkbox"
              value="Friend or Family"
              onChange={handleSourceChange}
            />
            Friend or Family
          </label>
          <label>
            <input
              type="checkbox"
              value="Internet"
              onChange={handleSourceChange}
            />
            Internet
          </label>
          <label>
            <input
              type="checkbox"
              value="Newsletter"
              onChange={handleSourceChange}
            />
            Newsletter
          </label>
          <label>
            <input
              type="checkbox"
              value="Other"
              onChange={handleSourceChange}
            />
            Other
          </label>
        </div>
      </div>
      <button className="submit-button" type="submit">Submit</button>
    </form>
  );
};

export default CustomForm;
