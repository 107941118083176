import { useLocation } from "react-router-dom";
import React, { useContext, useEffect } from 'react';
import { disablePageScroll, enablePageScroll } from "scroll-lock";
import { brainwave } from "../assets";
import { navigation } from "../constants";
import Button from "./Button";
import MenuSvg from "../assets/svg/MenuSvg";
import { HamburgerMenu } from "./design/Header";
import { useState } from "react";
import '../App.css'

const Header = () => {

  const reloadCount = 0;

  const [openModal, setOpenModal] = useState(false)
  const [openNavigation, setOpenNavigation] = useState(false);

  // const [userDetails, setUserDetails] = useState(null);
  const authorizedEmail = 'syedosamaaskari@gmail.com'; // Replace with the specific email

  const pathname = useLocation();
  
  // function refreshData() {
  //   const { user, isAuthenticated, logout } = useContext(AuthContext);
  // }

  const toggleNavigation = () => {
    if (openNavigation) {
      setOpenNavigation(false);
      enablePageScroll();
    } else {
      setOpenNavigation(true);
      disablePageScroll();
    }
  };

  

  const handleClick = () => {
    if (!openNavigation) return;

    enablePageScroll();
    setOpenNavigation(false);
  };

  

  return (
    
    <div
      className={`fixed top-0 left-0 w-full z-50  border-b border-n-6 lg:bg-n-8/90 lg:backdrop-blur-sm ${
        openNavigation ? "bg-n-8" : "bg-n-8/90 backdrop-blur-sm"
      }`}
    >
      
      <div className="flex items-center px-5 lg:px-7.5 xl:px-10 max-lg:py-4">
        <a className="block w-[12rem] xl:mr-8" href="#hero">
          <img className="bounce-in-right" src={brainwave} width={190} height={40} style={{margin: '25px'}} alt="BilliardLounge" />
        </a>

       

                
              
              
           


        {/* <a
          href="#signup"
          className="button hidden mr-8 text-n-1/50 transition-colors hover:text-n-1 lg:block"
        >
          New account
        </a>
        <div className="subtlenotification">
        <Button className="hidden lg:flex" href="#login">
          Sign in
        </Button>
        </div> */}


        
      </div>
    </div>
  );
};

export default Header;
