import React, { useState, useRef } from 'react';
import { curve, heroBackground } from "../assets";
import Button from "./Button";
import Section from "./Section";
import { BackgroundCircles, BottomLine, Gradient } from "./design/Hero";
import { heroIcons } from "../constants";
import { ScrollParallax } from "react-just-parallax";
import Generating from "./Generating";
import Notification from "./Notification";
import CompanyLogos from "./CompanyLogos";
import Modal from './Modal';
import CustomForm from './CustomForm';
import '../App.css';

const Hero = () => {
  const [showModal, setShowModal] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const parallaxRef = useRef(null);

  const handleJoinClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleFormSubmit = () => {
    setFormSubmitted(true);
    setShowModal(false);
  };

  return (
    <Section
      className="pt-[12rem] -mt-[5.25rem]"
      crosses
      crossesOffset="lg:translate-y-[5.25rem]"
      customPaddings
      id="hero"
    >
      <div className="container" ref={parallaxRef}>
        <div className="bounce-in-up relative z-1 max-w-[62rem] mx-auto text-center mb-[3.875rem] md:mb-20 lg:mb-[6.25rem]">
          <h1 className="h1 mb-6">
            Welcome to {` `}
            <span className="inline-block relative" style={{'color': 'orange'}}>
              Billiard Lounge!{" "}
              <img
                src={curve}
                className="absolute top-full left-0 w-full xl:-mt-2"
                width={624}
                height={28}
                alt="Curve"
              />
            </span><br></br>
          </h1>
          <>
            <h1 className="h2 mb-6" style={{fontWeight: '700'}}>We're Launching Soon!</h1>
            <p className="body-1 max-w-3xl mx-auto mb-6 text-n-2 lg:mb-8">
              We're launching soon with our new features exclusive to our members right here!
              Our members will have a list to choose from starting next month!
            </p>
          </>
          <>
            {formSubmitted ? (
              <p className="subtlenotification body-1 max-w-3xl mx-auto mb-6 text-n-2 lg:mb-8" >
                Thank you for joining the waiting list.<br></br> We'll connect back with you shortly!
              </p>
            ) : (
              <Button className='hithere' onClick={handleJoinClick} white> 
                Join waiting list?
              </Button>
            )}
          </>
        </div>
        <div className="relative max-w-[23rem] mx-auto md:max-w-5xl xl:mb-24">
          <div className="relative z-1 p-0.5 rounded-2xl bg-conic-gradient">
          </div>
          <Gradient />
          <div className="absolute h-full -top-[54%] left-1/2 w-[234%] -translate-x-1/2 md:-top-[46%] md:w-[138%] lg:-top-[104%]">
            <img
              src={heroBackground}
              className="w-full"
              width={1440}
              height={1800}
              alt="hero"
            />
          </div>
          <BackgroundCircles />
        </div>
        <CompanyLogos className="relative z-10 mt-20 lg:block" />
      </div>
      <Modal show={showModal} onClose={handleCloseModal}>
        <CustomForm onFormSubmit={handleFormSubmit} />
      </Modal>
    </Section>
  );
};

export default Hero;
