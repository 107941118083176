import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import './index.css'
import { auth } from './firebase';

const App = () => {
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     window.location.reload();
  //   }, 300000); // 300000 milliseconds = 5 minutes

  //   return () => clearInterval(interval); // Cleanup the interval on component unmount
  // }, []);

  const [user, setUser] = useState();

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setUser(user);
    });
  })

  return (
      <Router>
        <Routes>
          <Route exact path="/" element={<Home/>} />
        </Routes>
      </Router>
  );
};

export default App;
